.dapposBrandingContainer {
  cursor: pointer;
  position: absolute;
  bottom: 2%;
  right: 2%;
  border-radius: 0.5rem;
  padding: 10px;
  background-color: black;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
