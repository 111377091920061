.connectButtonItem {
  border: none;
  border-radius: 0.3rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-size: var(--medium-font-size);
  cursor: pointer;
  padding: 0.2% 4%;
  margin-top: 4%;
  margin-bottom: 4%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
