.dashboardRow {
  display: flex;
  align-items: center;
  color: var(--app-font-primary-color);
  height: 10%;
  font-size: var(--config-medium-font-size);
  font-weight: bold;
}

.dashboardRow-rightItem {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
