.errorMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--app-border-radius);
  background-color: var(--error-message-color);
  padding: 2%;
  margin: 20px 0 20px 0;
}
