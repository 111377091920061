.configOptionWithIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--secondary-color);
  background-color: var(--primary-color);
  padding: 2px;
}

.configOptionWithIcon-icon {
  margin-top: 6px;
}
