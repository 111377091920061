.alternativeAuthContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 0;
  align-items: center;
}

.alternativeAuthContainer-text {
  padding: 10px 0 10px 0;
}

.alternativeAuth-button {
  cursor: pointer;
}

.alternativeAuthContainer-noAccountText {
  margin-top: 20px;
  cursor: pointer;
}
