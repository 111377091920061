.planContainer {
  display: flex;
  flex-direction: column;
  padding: 2%;
  border-radius: var(--app-border-radius);
  background-color: var(--app-font-primary-color);
  color: var(--tertiary-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 20px;
}

.plan-tier {
  font-size: var(--large-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-price-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6% 0 6% 0;
}

.plan-price {
  font-weight: bold;
  font-size: var(--large-font-size);
  /* 
  display: flex;
  align-items: center;
  justify-content: center; */
  /* margin: 6% 0 6% 0; */
}
.plan-price-month-text {
  margin-top: 3%;
}

.plan-feature {
  margin: 8px 0 8px 0;

  font-size: var(--x-small-font-size);
}

.plan-features-container {
  margin: 20px 0 20px 0;
}
