.configContractsAndFunctionsModal {
}

.modal-container {
  background-color: var(--primary-color);

  /* position: absolute; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 200px;
  max-height: 12rem;
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}

.modal-title {
  display: flex;
}

.modal-button-two {
  cursor: pointer;
  font-size: var(--config-xSmall-font-size);
}
