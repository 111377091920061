.template-link-text {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5% 0 5% 0;
}

/* .createDapp {
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createDapp-container {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 50%;
  border-radius: 2%;
}

.createDapp-input {
  padding: 12px 20px;
  margin: 4px 0;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 40%;
}

.connect-contract-textArea {
  padding: 12px 20px;
  margin: 4px 0;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 40%;
  height: 30%;
}

.createDapp-button {
  background-color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 2%;
  box-sizing: border-box;
  min-width: 40%;
  height: 10%;

  color: var(--main-bg-white-color);
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
  margin: 4%;
}

.createDapp-button:hover {
  background-color: var(--primary-color-hover);
} */
