.configBubble {
  background-color: var(--app-font-primary-color);
  color: var(--tertiary-color);
  border-radius: var(--app-border-radius);
  display: flex;
  /* justify-content: center; */
  margin: 6px 0 6px 0;
  padding: 1% 4% 1% 4%;

  /* border-radius: 0.3rem;
  padding-left: 2%;
  margin-top: 2%;
  margin-bottom: 2%; */
  cursor: pointer;
}

.configBubble:hover {
  background-color: var(--app-font-primary-color-hover);
}
