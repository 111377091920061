.appGetHelp {
  display: flex;
  cursor: pointer;
  color: var(--app-font-primary-color);
  margin-top: 20%;
}

.appGetHelp-text {
  margin-left: 4px;
}
