.block {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80px;

  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 5% 0 5% 0;
}

@media screen and (max-width: 500px) {
  .block {
    width: 350px;
    margin: 5% 0 5% 0;
  }

  .block-inBuilder {
    width: 350px;
  }
}

@media screen and (max-width: 360px) {
  .block {
    width: 300px;
    margin: 5% 0 5% 0;
  }

  .block-inBuilder {
    width: 300px;
  }
}
