.configInput {
  margin-top: 4%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
}

.configInput-input {
  width: 80%;
  margin-bottom: 2%;
  padding: 3px 2px 3px 2px;
  border-radius: var(--app-border-radius);
  font-size: var(--small-font-size);
  /* width: 80%;
  border-radius: 0.3rem; */
}
