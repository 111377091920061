.form-container {
  background-color: var(--main-color);
  width: 100%;
  height: 90%;
  display: flex;

  justify-content: center;
  align-items: center;

  /* background-color: blueviolet; */
}

.form {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 2%;
  border-radius: var(--app-border-radius);
  background-color: var(--tertiary-color);
  color: var(--app-font-primary-color);
}

@media screen and (max-width: 500px) {
  .form {
    width: 80%;
  }
}

.form-icon {
  position: absolute;
}

.redirected-message {
  text-align: center;
  font-weight: bold;
}
