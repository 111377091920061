.dashboardButton {
  background-color: var(--secondary-color);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;

  border-radius: 4px;

  color: var(--main-bg-white-color);
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}
