.dashboardDappContract {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  border-radius: var(--app-border-radius);
  width: 42.2%;
  padding: 1%;
  margin: 5px 0 5px 0;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dashboardDappContract-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.dashboardDappContract-contractAddress {
  /* margin: 2% 0 2% 0; */
}

.dashboardDappContract-row-rightItem {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
