.account-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.account-settings-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  padding: 5% 0 5% 0;
}

.account-settings-row {
  display: flex;
  align-items: center;
  color: var(--app-font-primary-color);
  height: 10%;
  font-size: var(--config-medium-font-size);
  font-weight: bold;
}

.account-settings-options-container {
  display: flex;
  flex-direction: column;
  margin: 30px 0 30px 0;
  min-height: 20%;
}

.account-settings-option {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  border-radius: var(--app-border-radius);
  background-color: var(--tertiary-color);
  color: var(--app-font-primary-color);
  margin: 20px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.account-settings-cancel-button {
  background-color: var(--main-red-color);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;

  border-radius: 4px;

  color: var(--main-bg-white-color);
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}
