.dappsContainer {
  display: flex;
  flex-direction: column;
  margin: 30px 0 30px 0;
  min-height: 20%;
}

.dappsContainer.true {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
}

.noDapps-text {
  color: var(--app-font-primary-color);
  font-size: var(--config-small-font-size);
}
