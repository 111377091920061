.saveButton {
  border: none;
  padding: 0.2% 1.5%;
  border-radius: 0.3rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: bold;
  font-size: var(--medium-font-size);
  position: absolute;
  top: 2%;
  right: 2%;
}

.saveButton:hover {
  background-color: var(--secondary-color-hover);
}
