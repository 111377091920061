.pricingContainer {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  width: 90%;
  margin: 100px 0 100px 0;
}

.pricing-heading {
  color: var(--app-font-primary-color);
  font-size: var(--xx-large-font-size);
  /* background-color: blueviolet; */
  display: flex;
  justify-content: center;
  text-align: center;
}
.pricing-sub-heading {
  color: var(--app-font-primary-color);
  font-size: var(--small-font-size);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 4%;
  padding: 1%;
  border-radius: 4px;
  background-color: var(--tertiary-color);
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}

.pricing-sub-heading-2 {
  color: var(--app-font-primary-color);
  font-size: var(--small-font-size);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 4%;
  padding: 1%;
  border-radius: 4px;
  background-color: var(--secondary-color);
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}

.plans-container {
  display: flex;
  justify-content: center;
  margin: 50px 0 50px 0;
  /* background-color: brown; */
}

@media screen and (max-width: 410px) {
  .plans-container {
    flex-direction: column;
    width: 100%;

    /* width: 350px; */
  }
}

@media screen and (max-width: 360px) {
}
