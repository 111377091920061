.downloadDappBubble {
  cursor: pointer;
  position: absolute;
  bottom: 2%;
  right: 2%;
  display: flex;
  border-radius: 0.5rem;
  padding: 10px;
  background-color: var(--primary-color);
  /* background-color: var(--secondary-color); */

  color: white;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
