.colorPicker {
  display: flex;
}

.colorPicker-label {
  margin-right: 4%;
}

.form-control {
  width: 50px;
}

.colorPicker-square {
}
