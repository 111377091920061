.templateOptionsContainer {
  display: flex;
  margin: 12% 0 12% 0;
}

.template-icon {
  margin-left: 2px;
}

.template {
  opacity: 0.5;
  display: flex;
  margin-right: 10px;
  /* margin: 0 5px 0 5px; */
  padding: 4%;
  cursor: pointer;
  background-color: var(--main-bg-white-color);
  color: var(--primary-color);
  border-radius: var(--app-border-radius);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: var(--config-xSmall-font-size);
}

.selectedTemplate {
  border: solid var(--secondary-color);
  display: flex;
  margin-right: 10px;
  padding: 4%;
  cursor: pointer;
  background-color: var(--main-bg-white-color);
  color: var(--primary-color);
  border-radius: var(--app-border-radius);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: var(--config-xSmall-font-size);
}
