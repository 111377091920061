/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap"); */

html,
body,
#root,
.App {
  height: 100%;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  /* font-family: "Lato"; */

  background-color: var(--primary-color);
}

:root {
  --app-border-radius: 8px;
  --app-font-primary-color: #ffffff;
  --app-font-primary-color-hover: #e9e9e9;
  --main-background-color: #151719;

  --primary-color: #0e1117;
  --primary-color-hover: #141820;
  --secondary-color: #2d6df8;
  --secondary-color-hover: #145cf8;
  --tertiary-color: #1b2129;
  --tertiary-color-hover: #191d23;
  --error-message-color: red;

  --grey-bg-color: rgb(233, 233, 233);
  --main-bg-color: #89daff;
  --main-bg-white-color: #ffffff;
  --main-bg-white-color-hover: #e9e9e9;
  --dark-gray-color: rgb(136, 133, 133);
  --x-small-font-size: 1rem;
  --small-font-size: 1.2rem;
  --medium-font-size: 1.6rem;
  --large-font-size: 1.8rem;
  --x-large-font-size: 2.8rem;
  --xx-large-font-size: 3.2rem;

  --success-message-backgroundColor: #10bc00;
  --error-message-backgroundColor: #dd1a00;

  --config-xSmall-font-size: 1rem;
  --config-small-font-size: 1.2rem;
  --config-medium-font-size: 1.4rem;
  --config-large-font-size: 1.8rem;
  --config-x-large-font-size: 2.2rem;
  --main-red-color: rgb(200, 0, 0);
  --main-red-color-hover: rgb(161, 4, 4);
}
