.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input.noImage::before {
  content: "Upload image";
  border: none;
  padding: 1.5% 3%;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  cursor: pointer;
}
.custom-file-input.hasImage::before {
  content: "Change image";
  border: none;
  padding: 1.5% 3%;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  cursor: pointer;
}

.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
