.itemResponseMessage {
  display: flex;
  justify-content: center;
  text-align: center;

  border-radius: var(--app-border-radius);
  color: black;

  padding: 2%;
  margin: 20px 0 20px 0;
}
