.responseMessage {
  text-align: center;
  border-radius: 0.2rem;
  margin-top: 20px;
}

.responseMessage.success {
  background-color: var(--success-message-backgroundColor);
}

.responseMessage.error {
  background-color: var(--error-message-backgroundColor);
}
