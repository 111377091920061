.configStandardButton {
  background-color: var(--secondary-color);
  background-color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;

  border-radius: 2px;

  color: var(--main-bg-white-color);
  margin: 4% 0 4% 0;

  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 4% 0 rgb(0, 0, 0, 0.19);
}

.configStandardButton:hover {
  background-color: var(--primary-color-hover);
}
