.appHeader {
  display: flex;
  background-color: var(--tertiary-color);
  color: var(--app-font-primary-color);
  width: 100%;
  height: 8%;
  justify-content: center;
}

.appHeader-row {
  display: flex;
  width: 80%;
  height: 100%;
  justify-content: center;
}

.appHeader-leftRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
