.optionsModal {
}

.selectedOption-box {
  display: flex;
  width: 80%;
  background-color: var(--primary-color);
}

.options-container {
  margin-top: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 14%;

  max-height: 12rem;
}
