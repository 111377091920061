.section {
  width: 100%;
  min-height: 100%;
  /* min-height: 100%; */
  /* min-height: 200%; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background-color: var(--dark-gray-color); */
}

@media screen and (max-width: 1100px) {
  .section {
    flex-direction: column;
  }
}
