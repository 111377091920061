.configHeader {
  /* width: 100%; */

  display: flex;

  justify-content: center;
  align-items: center;
  background-color: var(--tertiary-color);
  color: var(--app-font-primary-color);
  font-size: var(--config-medium-font-size);
  border-radius: var(--app-border-radius) var(--app-border-radius) 0 0;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  padding: 2%;
}

.configHeader-title {
  width: 100%;
}

.configHeader-rightItem {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
