.configContainer {
  width: 80%;
  margin-bottom: 10%;
}

.configContent {
  display: flex;
  flex-direction: column;

  /* background-color: var(--tertiary-color); */
  background-color: var(--secondary-color);
  color: var(--app-font-primary-color);
  border-radius: 0 0 var(--app-border-radius) var(--app-border-radius);

  padding: 4%;
  /* margin: 20px 0 20px 0; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.configContainer-item {
  margin: 5% 0 5% 0;
}
