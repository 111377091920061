.dbSidebar {
  width: 30%;
  height: 100%;
  font-size: var(--config-medium-font-size);
  display: flex;
  flex-direction: column;
}

.sidebar-configContainers {
  /* height: 612px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-configContainers::-webkit-scrollbar {
  color: black;
}

.sidebar-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100px;
  padding: 20px;
}

.sidebar-bottom-container-two {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  padding: 20px;

  font-weight: bold;

  cursor: pointer;
  color: var(--main-bg-white-color);
  font-size: var(--config-xSmall-font-size);
}

.sidebar-bottom-container-two-text {
  margin-right: 2%;
}
