.dbCanvas {
  width: 100%;
  height: 100%;
  background-color: var(--tertiary-color);
  overflow-x: hidden;
  overflow-y: scroll;
}

.dbCanvas::-webkit-scrollbar {
  background-color: var(--tertiary-color);
}

.dbCanvas-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiary-color);
}
