.formButton {
  background-color: var(--secondary-color);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  margin: 4px 0;
  border-radius: 4px;

  /* border-radius: 2%;
  box-sizing: border-box;
  min-width: 40%;
  height: 10%; */

  color: var(--main-bg-white-color);
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
  margin: 4%;
}

.formButton:hover {
  background-color: var(--secondary-color-hover);
}
