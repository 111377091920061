.configDeleteButton {
  border: none;
  cursor: pointer;
  width: 60%;
  border-radius: 0.3rem;
  background-color: var(--main-red-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  margin-top: 4%;
}

.configDeleteButton:hover {
  background-color: var(--main-red-color-hover);
}
