.downloadDappContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* justify-content: center; */
  background-color: var(--tertiary-color);
  color: var(--app-font-primary-color);
  padding: 2%;
  height: 30%;
  width: 30%;
  border-radius: var(--app-border-radius);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.downloadDappContainer-title {
  font-size: var(--config-medium-font-size);
  font-weight: bold;
}

.downloadDappContainer-p {
  text-align: center;
}

.downloadDappContainer-buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadDappContainer-updateMessage {
  margin: 20px 0 20px 0;
  text-align: center;
}
