.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  padding: 5% 0 5% 0;
}

.dashboard-container-settings-icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
