.mobileViewMessage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--app-font-primary-color);
}
