.dashboardDapp {
  display: flex;
  flex-direction: column;
  padding: 2%;
  border-radius: var(--app-border-radius);
  background-color: var(--tertiary-color);
  color: var(--app-font-primary-color);
  margin: 20px 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dashboardDapp-row {
  display: flex;
  align-items: center;
  font-size: var(--config-medium-font-size);
  height: 20%;
  background-color: var(--tertiary-color);
  width: 44%;
}

.dashboardDapp-row-rightItem {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.dashboardDapp-bottomRow-itemOne {
  margin: 0 20px 0 20px;
}

.dashboardDapp-dappName {
}

.dashboardDapp-bottomRow {
  align-items: center;
  display: flex;
  width: 100%;
}

.dashboardDapp-bottomRow-rightItem {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.getting-started-guide-text {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  color: var(--secondary-color);
}
